import request from "@/request";
import { ResultCommon } from "@/types/common";
import {
  ForgetSubmitParamsType,
  ForgetSendCodeType,
} from "@/types/setPassword";
//忘记密码-发送邮箱验证码
export const forgetSendEmailCode = (
  data: ForgetSendCodeType
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/send/forget/pwd/email/code",
    data,
  });
};

//忘记密码-邮箱提交
export const forgetResetEmailSubmit = (
  data: ForgetSubmitParamsType
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/forget/pwd/email/reset",
    data,
  });
};

//忘记密码------手机------图片验证码------发送
export const forgetSendMobilePictureCode = (
  data: any
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/get/forget/password/mobile/verification/code",
    data,
  });
};

//忘记密码------手机------图片验证码------校验
export const forgetCheckMobilePictureCode = (
  data: any
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/check/forget/password/mobile/verification/code",
    data,
  });
};
//忘记密码------手机------短信验证码------发送
export const forgetSendMobileCode = (
  data: ForgetSendCodeType
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/send/forget/password/mobile/sms",
    data,
  });
};

//忘记密码------手机------提交------重置
export const forgetMobileSubmit = (
  data: ForgetSubmitParamsType
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/forget/password/mobile/reset",
    data,
  });
};

/**
 *
 * @param data
 *  修改密码
 */

//修改密码-发送邮箱验证码
export const changeSendEmailCode = (data: any): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/send/reset/pwd/email/code",
    data,
  });
};

//修改密码-邮箱提交
export const changeResetEmailSubmit = (data: any): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/reset/pwd/email",
    data,
  });
};

//修改密码------手机-------数字验证码------发送
export const changeSendMobilePictureCode = (
  data: any
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/get/reset/password/mobile/verification/code",
    data,
  });
};

//修改密码------手机-------数字验证码------校验
export const changeCheckMobilePictureCode = (
  data: any
): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/check/reset/password/mobile/verification/code",
    data,
  });
};
//修改密码------手机-------短信验证码------发送
export const changeSendMobileCode = (data: any): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/send/reset/password/mobile/sms",
    data,
  });
};

//修改密码------手机号+短信验证码+新密码------重置
export const changeMobileSubmit = (data: any): Promise<ResultCommon> => {
  return request.post<ResultCommon>({
    url: "/v-user/reset/password/mobile",
    data,
  });
};
